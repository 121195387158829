import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import {Link, Navigate, useParams, useLocation} from "react-router-dom";
import { ReactComponent as RunnerIcon } from '../images/runner.svg'
import { ReactComponent as ApparelIcon } from '../images/apparel.svg'
import { ReactComponent  as Accessories } from '../images/accessories.svg'
import { ReactComponent  as Cleated } from '../images/cleated.svg'
import { ReactComponent as Returns } from '../images/returns.svg'
import { ReactComponent as HomeGoods } from '../images/home_goods.svg'


import {GetProducts, GetProductsBySlug, GetTrends} from '../routes/routes';
import { useFetchData } from "../hooks/hooks";
import {sortProducts} from "../libs/libs";

const statuses = {
    Stocked: 'text-green-700 bg-green-50 ring-green-600/20',
    Withdraw: 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Sold: 'text-red-700 bg-red-50 ring-red-600/10',
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const imageComponents = {
    athletic: RunnerIcon,
    apparel: ApparelIcon,
    accessories: Accessories,
    cleated: Cleated,
    returns: Returns,
    home: HomeGoods,
};


/**
 * Fetches data from an API and creates a grid layout.
 * @returns {JSX.Element} The grid layout component.
 */
// DynamicGrid component definition
export default function DynamicGrid() {

    // Get 'name' from URL params.
    const { name } = useParams();

    const location = useLocation();
    const isHomePage = location.pathname === '/';

    // Fetch appropriate data based on route. We're treating 'name' as a category slug.
    const { data: fetchedData, error: dataError, isLoading: dataLoading } =
        useFetchData(
            isHomePage ? GetTrends() :
                name ? GetProductsBySlug(name) : GetProducts()
        );

    // Sort the data from libs function
    let sortedData;
    if (fetchedData && Array.isArray(fetchedData)) {
        sortedData = sortProducts(fetchedData);
    }

    // Loading state
    if (dataLoading) {
        return <div className="font-medium text-indigo-600">Loading...</div>;
    }

    // Error state
    if (dataError) {
        return (
            <div>
                <p>{dataError.message}</p>
            </div>
        )
    }

    // If the data doesn't exist, navigate to the 404 page
    if (!dataError && !dataLoading && !fetchedData) {
        return <Navigate to='/404' />;
    }


    return (
        <div>
            {!isHomePage && (
                <div className="bg-white">
                    <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 -mb-4 sm:-mb-6">
                        <h2 className="sr-only">Products</h2>
                        <div>
                            <a href="/"
                               className="hidden text-sm font-medium text-indigo-600 hover:text-indigo-500 md:block">
                                Go back
                                <span aria-hidden="true"> &larr;</span>
                            </a>
                        </div>
                    </div>
                </div>
            )}
            <br />
            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
            <div className="mx-auto max-w-2xl px-4 sm:py-20 sm:px-6 lg:max-w-7xl lg:px-8">
                <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                    {/* Render trendsData if on the front page, otherwise render productsData */}
                    {sortedData.map((client) => {
                        // Default to 'img' if no matching component
                        const ImageComponent = imageComponents[client.slug] || 'img';
                        return (
                            <li key={client.id} className="overflow-hidden rounded-xl border border-gray-200">
                                <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                                    <div className="relative h-12 w-12 flex-none rounded-lg bg-gradient-to-r from-purple-500 via-blue-500 to-indigo-500 p-0.5">
                                        <div className="relative h-full w-full bg-white rounded-lg shadow-xl">
                                            <ImageComponent
                                                src={client.imageUrl}
                                                alt={client.title}
                                                className="h-full w-full object-cover rounded-lg"
                                            />
                                        </div>
                                    </div>
                                    <div className="text-sm font-medium leading-6 text-gray-900">{client.title}</div>
                                    <Menu as="div" className="relative ml-auto">
                                        <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                                            <span className="sr-only">Open options</span>
                                            <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                                        </Menu.Button>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link to={`/products/lot/${client.id}`}
                                                              className={classNames(
                                                                  active ? 'bg-gray-50' : '',
                                                                  'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                              )}
                                                        >
                                                            View<span className="sr-only">, {client.slug}</span>
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href={client.paymentUrl}
                                                            className={classNames(
                                                                active ? 'bg-gray-50' : '',
                                                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                            )}
                                                        >
                                                            Buy Now<span
                                                            className="sr-only">, {client.paymentUrl}</span>
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                                <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                                    <div className="flex justify-between gap-x-4 py-3">
                                        <dt className="text-gray-500">Total Units</dt>
                                        <dd className="text-gray-700">
                                            <time>{client.totalUnits}</time>
                                        </dd>
                                    </div>
                                    <div className="flex justify-between gap-x-4 py-3">
                                        <dt className="text-gray-500">Price</dt>
                                        <dd className="flex items-start gap-x-2">
                                            <div className="font-medium text-gray-900">${client.price}</div>
                                            <div className={classNames(
                                                statuses[client.status],
                                                'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                            )}>
                                                {client.status}
                                            </div>
                                        </dd>
                                    </div>
                                </dl>
                            </li>
                        );
                    })}
                </ul>
                <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                </div>
            </div>
        </div>
    );
}

